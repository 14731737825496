import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

//define a routes
const routes = [
    // {
    //     path: '/',
    //     name: 'home',
    //     component: () => import( /* webpackChunkName: "landing" */ '@/views/DashboardView.vue'),
    //     meta: {
    //         allAuth: true,
    //     }
    // },
    {
        path: '/login',
        name: 'Login',
        component: () => import( /* webpackChunkName: "auth" */ '@/views/auth/LoginView.vue'),
        meta: {
            auth: false,
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import( /* webpackChunkName: "home" */ '@/views/auth/RegisterView.vue'),
        meta: {
            auth: false,
        }
    },

    // // admin

    {
        path: '/',
        name: 'Halaman Dashboard Admin',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/user/DashboardView.vue'),
        meta: {
            auth: true,
        }
    },
    {
        path: '/user/jadwal',
        name: 'Halaman Dashboard Admin',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/user/JadwalGamelan.vue'),
        meta: {
            auth: true,
        }
    },
    
    {
        path: '/user/asset',
        name: 'Halaman Dashboard Admin',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/user/AssetGamelan.vue'),
        meta: {
            auth: true,
        }
    },

    {
        path: '/user/editProfile',
        name: 'Chat Box',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/user/EditProfile.vue'),
        meta: {
            auth: true,
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    // base: ,
    routes
})

router.beforeEach((to, from, next) => {
    // if (to.matched.some(record => record.meta.auth) != "all" && !localStorage.getItem('token')) {
    //     router.go("/login")
    // }


    if (to.matched.some(record => record.meta.allAuth)) {
        next()
    } else if (!to.matched.some(record => record.meta.auth) && store.state.auth.isLoggedIn) {
        next("/")
    } else if (to.matched.some(record => record.meta.auth) && !store.state.auth.isLoggedIn) {
        router.push("/login")
    } else {
        next()
    }
});

export default router