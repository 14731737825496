import Vue from 'vue'
import axios from 'axios'
import VueToast from 'vue-toast-notification'

import App from './App.vue'
import router from './router'
import store from './store'

import 'vue-toast-notification/dist/theme-default.css'
import vuetify from './plugins/vuetify'


Vue.config.productionTip = false

axios.defaults.withCredentials = true
axios.defaults.baseURL = "http://165.22.255.208:4005"

Vue.prototype.$apiURL = "http://165.22.255.208:4005"

Vue.use(VueToast)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
